import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./_document-listing.scss";
import {
  Button,
  Container,
  Row,
  Col,
  Input,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Badge,
} from "reactstrap";
import Select, { components } from "react-select";
import {
  AiFillCaretDown,
  AiFillCloseCircle,
  AiOutlineCheck,
} from "react-icons/ai";
import { HiSortAscending, HiSortDescending } from "react-icons/hi";
import Pagination from "components/Pagination/Pagination";
import { RiSearchLine } from "react-icons/ri";
import { FiGrid } from "react-icons/fi";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { BsFilterLeft } from "react-icons/bs";
import ListImage from "../../../assets/img/list_image.png";
import GridImage from "../../../assets/img/grid_image.png";
import NotFound from "../../../assets/img/no-results.png";
const sortNames = [
  { label: "Relevance", value: "relevance" },
  { label: "Project Name", value: "project_name" },
  { label: "Activity No", value: "activity_number" },
  { label: "Operation", value: "operation" },
];

const DocumentListing = ({
  onFindResults,
  data,
  orderType,
  onSort,
  onDDChange,
  formField,
  onResetFilters,
  projectsOptions,
  onFormFieldChange,
  selectedValues,
  sortingValue,
  filtercount,
  onCurrentPageChange,
  loader,
  pageno,
}) => {
  const { dataset, document_count, pagination } = data;
  const { current_page, per_page, total_count, total_pages } = pagination;
  const { selected_project_name } = selectedValues;
  const history = useHistory();
  const [grid, setGrid] = useState(true);
  const [showModal, setShowModal] = useState(true);

  const style = {
    control: (base) => ({
      ...base,
      border: "none",
      // This line disable the blue border
      boxShadow: "none",
    }),
    zindex: 99999,
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <AiFillCaretDown />
      </components.DropdownIndicator>
    );
  };
  const searchKeyword = (e) => {
    if (e.keyCode === 13) {
      onFindResults(formField);
    }
  };
  const keyword = formField.keyword !== null ? formField.keyword : " ";
  const gridList = { display: "flex", justifyContent: "space-between" };

  const list = { display: "inline-grid", justifyContent: "flex-end" };

  const checkIcon = (
    <AiOutlineCheck color="green" className="  float-right pb-2" size={30} />
  );
  let startingPage = 1;
  startingPage = per_page * current_page - per_page + 1;

  let endingPage = 1;
  let diff1 = 1;
  let diff2 = 1;
  let totalPages = total_pages * per_page;
  let cpage = current_page * per_page;
  if (cpage > total_count) {
    diff1 = totalPages - total_count;
    diff2 = total_count - diff1;
    endingPage = diff1 + diff2;
  } else {
    endingPage = current_page * per_page;
  }
  return (
    <>
      <div className="section pb-3">
        <Container fluid className="pt-2">
          <Row>
            <Col className="pr-0" style={{ maxWidth: 285 }}>
              <div className="sidebar">
                <div className="mb-2">
                  <h2 className="d-inline" style={{ marginLeft: "-0.5rem" }}> Filters</h2>
                  {Math.abs(filtercount) > 0 ? (
                    <Badge className="bg-stech d-inline ml-2 mb-1 filter_badge">
                      <span className="filter_text">
                        {filtercount > 0 ? 0 : Math.abs(filtercount)}
                      </span>
                    </Badge>
                  ) : null}
                  <p
                    onClick={onResetFilters}
                    className="d-inline float-right mt-2 stech-text pointer-hand"
                  >
                    Clear
                  </p>
                </div>

                <Row>
                  
                    <Col md={12} className="p-1 mb-3 filter_fields">
                      <label className="mb-2 description">Project Name</label>
                      <Select
                        name="project_name"
                        className="dropdown"
                        components={{ DropdownIndicator }}
                        // styles={style}
                        value={selected_project_name}
                        onChange={(selectedOption, name) =>
                          onDDChange(selectedOption, name)
                        }
                        options={projectsOptions}
                        classNamePrefix="react-select"
                      />
                    </Col>

                    <Col md={12} className="p-1 mb-3 filter_fields">
                      <div className="description">
                        <label className="mb-2">Project No</label>
                        <Input
                          name="project_number"
                          value={formField.project_number || ""}
                          placeholder="Project Number"
                          type="text"
                          maxLength={30}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={onFormFieldChange}
                        />
                      </div>
                    </Col>
                    <Col md={12} className="p-1 mb-3 filter_fields">
                      <div className="description">
                        <label className="mb-2">Discipline</label>
                        <Input
                          name="descipline"
                          value={formField.descipline || ""}
                          placeholder="Discipline"
                          type="text"
                          maxLength={30}
                          onChange={onFormFieldChange}
                        />
                      </div>
                    </Col>

                    <Col md={12} className="p-1 mb-3 filter_fields">
                      <div className="description">
                        <label className="mb-2">Activity</label>
                        <Input
                          name="activity_number"
                          value={formField.activity_number || ""}
                          placeholder="Activity"
                          type="text"
                          maxLength={30}
                          onChange={onFormFieldChange}
                        />
                      </div>
                    </Col>
                    <Col md={12} className="p-1 mb-3 filter_fields">
                      <div className="description">
                        <label className="mb-2">Operation</label>
                        <Input
                          className=""
                          name="operation"
                          value={formField.operation || ""}
                          placeholder="Operation"
                          type="text"
                          maxLength={30}
                          onChange={onFormFieldChange}
                        />
                      </div>
                    </Col>
                    <Col className="p-1 text-center filter_fields" md={12}>
                      <Button
                        onClick={() => onFindResults(formField)}
                        style={{ height: "100%" }}
                        className="btn-round btn-block btn-dander-secondary"
                      >
                        Apply Filters
                      </Button>
                    </Col>
                 
                </Row>
              </div>
            </Col>

            <Col>
              <Row>
                <Col className="p-0 mb-2" sm={12} md={12} lg={12} >
                  <div className="search-bg">
                    <InputGroup className="has-danger">
                      <Input
                        name="keyword"
                        value={formField.keyword || ""}
                        className="form-control-danger"
                        id="inputSuccess1"
                        placeholder="Search for keyword..."
                        type="text"
                        onKeyDown={(e) => {
                          searchKeyword(e);
                        }}
                        onChange={onFormFieldChange}
                      />
                      <AiFillCloseCircle
                        onClick={() => onFormFieldChange("keyword")}
                        color="#999999"
                        className="pointer-hand"
                        style={{
                          position: "absolute",
                          top: 11,
                          right: 64,
                          zIndex: 555,
                        }}
                        size={20}
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText
                          style={{
                            borderRadius: "0px 5px 5px 0",
                            border: " #8C0001 1px solid",
                          }}
                          className="bg-stech"
                        >
                          <RiSearchLine
                            color="#fff"
                            className="pointer-hand"
                            size={25}
                            onClick={() => onFindResults(formField)}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                  {loader ? (
                    <div className="loader-center">
                      <div
                        style={{ border: "4px solid #c05151" }}
                        className="ld-loader ld-book"
                      >
                        <figure
                          style={{ border: "4px solid #c05151" }}
                          className="ld-page"
                        ></figure>
                        <figure
                          style={{ border: "4px solid #c05151" }}
                          className="ld-page"
                        ></figure>
                        <figure
                          style={{ border: "4px solid #c05151" }}
                          className="ld-page"
                        ></figure>
                      </div>
                    </div>
                  ) : null}
                  <div className="pl-3">
                    <div>
                      <p style={{ marginTop: "2rem" }}>
                        <span
                          className="home pointer-hand"
                          onClick={() => {
                            history.push("/home");
                          }}
                        >
                          Home{" "}
                        </span>
                        <span className="search_result">/ Search Results</span>
                      </p>

                      {keyword.length > 1 ? (
                        <h1 className="ml-2 medium-message">
                          Matching results for{" "}
                          <span
                            style={{ color: "black" }}
                            className="font-weight-bold"
                          >{`"${keyword}"`}</span>
                        </h1>
                      ) : null}
                    </div>
                    <span className="ml-2 mt-2 float-left smaller-message">
                      Use filters for more accurate results
                    </span>

                    {/* <span
                        className="float-right"
                          style={{
                            border: "#36454F 1px solid",
                            borderRadius: "5px",
                            padding: 5,
                            marginRight: 20,
                            paddingBottom:1
                          }}
                        >
                          {orderType === "asc" ? (
                            <HiSortAscending
                              onClick={() => onSort("asc")}
                              size={30}
                              color="gray"
                            />
                          ) : (
                            <HiSortDescending
                              onClick={() => onSort("desc")}
                              size={25}
                              color="gray"
                            />
                          )}
                        </span> */}

                    <a
                      style={{ display: !showModal ? "initial" : "none" }}
                      className="card4"
                      href="#"
                    >
                      <h5 className="description mt-2">SORT BY</h5>
                      <hr />
                      {sortingValue === "relevance" ? checkIcon : ""}
                      <h5
                        onClick={() => onSort("relevance")}
                        className="sort-text font-weight-normal pt-2 "
                      >
                        Relevance{" "}
                      </h5>
                      <hr />
                      {sortingValue === "project_name" ? checkIcon : ""}
                      <h5
                        onClick={() => onSort("project_name")}
                        className="sort-text font-weight-normal pt-2"
                      >
                        Project Name{" "}
                      </h5>
                      <hr />
                      {sortingValue === "activity_number" ? checkIcon : ""}
                      <h5
                        onClick={() => onSort("activity_number")}
                        className="sort-text font-weight-normal pt-2"
                      >
                        Activity Number{" "}
                      </h5>
                      <hr />
                      {sortingValue === "operation" ? checkIcon : ""}
                      <h5
                        onClick={() => onSort("operation")}
                        className="sort-text font-weight-normal pt-2"
                      >
                        {" "}
                        Operation
                      </h5>
                    </a>
                  </div>
                  <div className="float-right pt-1 pr-1">
                    <span
                      style={{
                        border: "#36454F 1px solid",
                        borderRadius: "5px",
                        padding: 10,
                        marginRight: 32,
                      }}
                    >
                      <BsFilterLeft
                        onClick={() => setShowModal(!showModal)}
                        className="mr-1 pb-1"
                        size={25}
                      />
                    </span>
                  </div>
                  <div className="float-right pt-1 pr-1">
                    <span
                      className={`${grid ? "content-bg-2" : "content-bg-1"}`}
                      style={{
                        border: "#36454F 1px solid",
                        borderRadius: "5px",
                        padding: 10,
                      }}
                    >
                      <AiOutlineUnorderedList
                        onClick={() => setGrid(true)}
                        className="mr-1 pb-1"
                        size={25}
                      />
                      <FiGrid
                        onClick={() => setGrid(false)}
                        className="ml-1 pb-1"
                        size={25}
                      />
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className=" mr-2 mt-2 ">
              {dataset && dataset.length > 0 ? (
                dataset.map((item, index) => {
                  return (
                   
                      <Col lg={grid ? 12 : 4}>
                        <Card
                          onClick={() =>
                            history.push(
                              `/pdf-viewer/${item.id}/${
                                formField.keyword ? formField.keyword : ""
                              }`
                            )
                          }
                          className="card listing-card pointer-hand"
                          key={index}
                        >
                          {!grid ? (
                            <Row>
                              <Col lg={grid ? 3 : 12} md={3} sm={12}>
                                <img
                                  alt="..."
                                  height={160}
                                  width={grid ? 160 : "100%"}
                                  style={{ maxHeight: grid ? 160 : "100%" }}
                                  className={`${!grid ? "pt-0 pl-0" : ""}`}
                                  src={!grid ? GridImage : ListImage}
                                />
                              </Col>
                              <CardBody>
                                <Col lg={9} md={9} sm={12}>
                                  <h2
                                    style={{ fontSize: "1.2em" }}
                                    // onClick={() =>
                                    //   history.push(
                                    //     `/pdf-viewer/${item.id}/${
                                    //       formField.keyword
                                    //         ? formField.keyword
                                    //         : ""
                                    //     }`
                                    //   )
                                    // }
                                    className="mt-2 h2"
                                  >
                                    {`${item.project_name} (${item.supplier_name}) - ${item.project_number} ${item.descipline}`}
                                  </h2>

                                  <div style={grid ? gridList : list}>
                                    <h3 className="listing-text d-inline">
                                      Discipline: {item.descipline}
                                    </h3>
                                    <h3 className="listing-text d-inline">
                                      Operation: {item.operation}
                                    </h3>
                                    <h3 className="listing-text d-inline">
                                      File Number: {item.file_tracking_number}
                                    </h3>
                                  </div>
                                  {/* <h3 className="mt-1 font-weight-bold">
                            Occurrences {item.pages_count}
                          </h3> */}
                                </Col>
                              </CardBody>
                            </Row>
                          ) : (
                            <CardBody>
                              <Row class="d-flex flex-row">
                                <div className="align-self-start">
                                  <img
                                    alt="..."
                                    height={160}
                                    // width={grid ? 160 : '100%'}
                                    style={{ marginLeft: grid ? 15 : null }}
                                    className={`${!grid ? "pt-0 pl-0" : ""}`}
                                    src={!grid ? GridImage : ListImage}
                                  />
                                </div>

                                <Col lg={9} md={9} sm={12}>
                                  <h2
                                    className="mt-2 h2"
                                    style={{ fontSize: "1.2em" }}
                                  >
                                    {`${item.project_name} (${item.supplier_name}) - ${item.project_number} ${item.descipline}`}
                                  </h2>
                                  <Row>
                                    {/* <div style={gridList}> */}
                                    <Col>
                                      <h3 className="listing-text d-inline">
                                        Discipline: {item.descipline}
                                      </h3>
                                    </Col>
                                    <Col>
                                      <h3 className="listing-text d-inline">
                                        Operation: {item.operation}
                                      </h3>
                                    </Col>
                                    <Col>
                                      <h3 className="listing-text d-inline">
                                        File Number: {item.file_tracking_number}
                                      </h3>
                                    </Col>
                                    {/* </div> */}
                                  </Row>
                                  {grid ? (
                                    <p className="mb-0 mt-2 listing-text">
                                      {item.page_text?.length > 150
                                        ? item.page_text.substring(0, 200) +
                                          "..."
                                        : item.page_text}
                                    </p>
                                  ) : null}
                                  {/* <h3 className="mt-1 font-weight-bold">
                                  Occurrences {item.pages_count}
                        </h3> */}
                                </Col>
                              </Row>{" "}
                            </CardBody>
                          )}
                        </Card>
                      </Col>
                  
                  );
                })
              ) : (
                <Row style={{width:"100%"}}>
                  <Col>
                    <div className="d-block  text-center">
                      <img src={NotFound} alt="No Recod Found" style={{userSelect:"none"}} />
                      <h1 className="ml-2  mb-3 larger-message">
                         No matching results for{" "}
                          <span
                            style={{ color: "black" }}
                            className="font-weight-bold"
                          >{`"${keyword}"`}</span>
                        </h1>
                        <span className="listing-text">
                        Try searching for something else
                    </span>
                    </div>
                  </Col>
                </Row>
              )}
            </Row>
              {total_pages > 0 ? (
                <div className="center-pagination ">
                  <div className="pt-2 text-center ">
                    <span className=" flex-child text-dark font-weight-normal">
                      Showing {startingPage} - {endingPage} of {total_count}{" "}
                      records
                    </span>
                  </div>
                  <Pagination
                    pageno={pageno}
                    onCurrentPageNo={onCurrentPageChange}
                    totalRecords={total_count}
                    pageLimit={parseInt(per_page)}
                    currentPage={parseInt(current_page)}
                    totalPages={total_pages}
                    pageNeighbours={total_pages > 99 ? 2 : 1}
                  />
                </div>
              ) : null}
            </Col>

            <br />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DocumentListing;
