import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import { useHistory } from "react-router-dom";
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
} from "reactstrap";
import { MdUpload } from "react-icons/md";
function IndexNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState(
    !props.defaultColor ? "navbar-transparent " : ""
  );
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const navigate = useHistory();
  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  const show = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        (document.documentElement.scrollTop < 300 ||
          document.body.scrollTop < 300) &&
        props.defaultColor === false
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  const onLogout = () => {
    localStorage.clear();
    history.push("/login");
  };
  return (
    <Navbar className={classnames("fixed-top navbar-transparent")} expand="lg">
      <div className="navbar-translate">
        <NavbarBrand
          data-placement="bottom"
          onClick={() => navigate.push("/index")}
          target="_blank"
        >
          <h4 className="mt-2 mb-0 stech-text">Digital &nbsp; Library</h4>
        </NavbarBrand>

        <NavbarBrand
          data-placement="bottom"
          onClick={() => navigate.push("/index")}
          target="_blank"
        >
          <img
            src={require("../../assets/img/stech.png")}
            alt="stech logo Text"
          />
        </NavbarBrand>
        <button
          aria-expanded={navbarCollapse}
          className={classnames("navbar-toggler navbar-toggler", {
            toggled: navbarCollapse,
          })}
          onClick={toggleNavbarCollapse}
        >
          <span className="navbar-toggler-bar bar1" />
          <span className="navbar-toggler-bar bar2" />
          <span className="navbar-toggler-bar bar3" />
        </button>
      </div>
      <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
        <Nav navbar>
          <NavItem>
            {show && show.perms === null ? (
              <Button
                id="btn-upload-doc"
                className="btn-round"
                color="secondary"
                onClick={() => navigate.push("/add-document")}
                target="_blank"
                style={{ minInlineSize: "200px" }}
              >
                <MdUpload size={22} className="mb-1" /> Upload Document
              </Button>
            ) : null}
            <span
              style={{ cursor: "pointer" }}
              className="ml-2 stech-text"
              href="#"
              onClick={() => onLogout()}
            >
              Logout
            </span>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default IndexNavbar;
