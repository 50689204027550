import axios from "axios";
import { LOGIN } from "../utils/apiUrls";
import { BASE_URL } from "utils/apiUrls";
import LocalStorageService from "./LocalStorageService";

const register = (username, email, password) => {
  return axios.post(BASE_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .post(LOGIN, {
      username,
      password,
    })
    .then((response) => {
      if (response.data.code === 200) {
        LocalStorageService.setToken(response.data.data.tokens);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("isAuthenticated", true);
      }
      else if (response.data.code !== 200) {
        LocalStorageService.setToken(response.data.data.tokens);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("isAuthenticated", true);      
      }
    });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("isAuthenticated");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
const isAuthenticated = () => {
  return JSON.parse(localStorage.getItem("isAuthenticated"));
};

const AuthService = {
  register,
  login,
  logout,
  isAuthenticated,
  getCurrentUser,
};

export default AuthService;
