import React, { useCallback, useEffect, useRef, useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import HomePage from "components/Headers/HomePage.js";
import Footer from "components/Footers/Footer.js";

import DocumentListing from "views/index-sections/DocumentListing/DocumentListing";
import {
  getAllDocumentsList,
  getAllProjectsList,
} from "./index-sections/DocumentListing/apiCalls";

const Main = () => {
  const [data, setData] = useState();
  const [reidrect, setRedirect] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [orderType, setSortValue] = useState("desc");
  const [orderBy, setOrderBy] = useState("relevance");
  const [filtercount, setFilterCount] = useState(0);
  const [formField, updateFormField] = useState({
    project_name: null,
    project_number: null,
    keyword: null,
    descipline: null,
    activity_number: null,
    operation: null,
  
  });
  const [  page_no, setPageNo] = useState(1)
  const [  loader, setLoader] = useState(false)
  const [selectedValues, setSelectedValues] = useState({
    selected_project_name: { label: "All Projects", value: "" }
  });
  const [sortingValue, setSortingValue] = useState("relevance")

  const getAllDocuments = useCallback(() => {
    setLoader(true)

    let { selected_project_name } = selectedValues;
    const { per_page, keyword, project_number, descipline, activity_number, operation } =
      formField;
    getAllDocumentsList(
      per_page,
        page_no,
      orderType,
      sortingValue ? sortingValue: null,
      selected_project_name.label !== "All Projects"
        ? selected_project_name.label
        : null,
      keyword ? keyword : null,
      project_number ? project_number : null,
      descipline ? descipline : null,
      activity_number ? activity_number : null,
      operation ? operation : null
    ).then((response) => {
      if (response.code === 200) {
        setLoader(false)
        setRedirect(true)
        setData(response.data);
      } else if (response.code === 400) {
        setLoader(false)
        setRedirect(true)
        alert(response.errors);
      }
    });
  });
  useEffect(() => {
    getProjectsList();
  }, []);
  // useEffect(() => {
  //   onFilterRecords(formField);
  // }, [formField.page_no]);
 
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  function useSortingRef(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  function usePreviousPage(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  function useSortRef(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevValue = usePrevious({ orderType });
  const sortValue = useSortRef({ orderBy });
  const prevPage = usePreviousPage({ page_no });
  const sortingVal = usePreviousPage({ sortingValue });
  useEffect(() => {
    if (sortValue && sortValue.orderBy !== orderBy) {
      getAllDocuments();
      return;
    }
    if (prevValue && prevValue.orderType !== orderType) {
      getAllDocuments();
    }
    if (prevPage && prevPage.page_no !== page_no) {
      getAllDocuments();
    }
    if (sortingVal && sortingVal.sortingValue !== sortingValue) {
      getAllDocuments();
    }
  }, [getAllDocuments, orderType,page_no, prevPage,sortingVal, sortValue,sortingValue, prevValue, orderBy, ]);
  const onFilterRecords = (formValues) => {
    calculateFiltersCount()
    setLoader(true)
    let { selected_project_name } = selectedValues;
    const {per_page}= formField
    const { keyword, project_number, descipline, activity_number, operation } =
      formValues;
    getAllDocumentsList(
      per_page,page_no,
      orderType,
      sortingValue ? sortingValue : null,
      selected_project_name.label !== "All Projects"
        ? selected_project_name.label
        : null,
      keyword ? keyword : null,
      project_number ? project_number : null,
      descipline ? descipline : null,
      activity_number ? activity_number : null,
      operation ? operation : null
    ).then((response) => {
      if (response.code === 200) 
      {
        setRedirect(true)
        setLoader(false)

        setData(response.data);
      } else if (response.code === 400) {
        setRedirect(true)
        setLoader(false)
        alert(response.errors);
      }
    });
  };
  const getProjectsList = () => {
    getAllProjectsList().then((response) => {
      if (response.code === 200) {
        setProjectOptions(response.data);
      } else if (response.code !== 400) {
        console.log(response.errors);
      }
    });
  };
  const onSort = (value) => {
    if(value === "ascending"){
      setSortValue("asc")
      setSortingValue("ascending")
      
    }
    else if(value === "descending"){
      setSortValue("desc")
      setSortingValue("descending")
    }
    else if(value === "relevance"){
      setSortingValue("relevance")
    }
    else if(value === "project_name"){
      setSortingValue("project_name")
    }
    else if(value === "activity_number"){
      setSortingValue("activity_number")
    }
    else if(value === "operation"){
      setSortingValue("operation")
    }
    else{
      setSortingValue("relevance")
    }
  };
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  const validateField = ({ name, value }) => {
    updateFormField({
      ...formField,
      [name]: value,
    });
  };
  const onFormFieldChange = (e) => {
    if(e==="keyword"){
      updateFormField({
        ...formField,
        keyword: "",
      });
      return
    }
    const { name, value } = e.target;
    if (
     name === "project_number" ||
      name === "descipline" ||
     name === "activity_number" ||
     name === "operation" || name === "keyword"
    ) { 
      validateField({ name, value });
      return;
    }
   
  };
  const onResetFilters = () => {
    updateFormField({
      project_number: "",
      project_name: "",
      keyword: "",
      descipline: "",
      activity_number: "",
      operation: "",
    });
    setSelectedValues({
      selected_project_name: { label: "All Projects", value: "" },
    });
    setFilterCount(0)
  };
  const handleCurrentPageChange = (pageno) => {
    setPageNo(pageno);
  }
  const calculateFiltersCount =()=>{
    const emptyValues = new Set(["", null, undefined]); 
    const {keyword, ...newObj} =formField; 
   let counter = Object.values(newObj).filter(x => emptyValues.has(x)).length;
   setFilterCount( counter -5)
  }
  const handelDropDownChange = (selectedOption, obj) => {

    if (!selectedOption) {
      let defaultOption = { value: "", label: "All Projects" };
      if (obj.name === "project_name") {
        setSelectedValues({
          ...selectedValues,
          [`selected_${obj.name}`]: defaultOption,
        });
      }
      return;
    }

    const { name } = obj;

    if (obj.name === "project_name") {
      updateFormField({
        ...formField,
        [name]: selectedOption.value,
      });
      setSelectedValues({
        ...selectedValues,
        [`selected_${name}`]: selectedOption,
      });
      setSelectedValues({
        ...selectedValues,
        [`selected_${obj.name}`]: selectedOption,
      });
    } else if (obj.name === "sort") {
      updateFormField({
        ...formField,
        [name]: selectedOption.value,
      });
      setOrderBy(selectedOption.value);
      setSelectedValues({
        ...selectedValues,
        [`selected_${name}`]: selectedOption,
      });
      setSelectedValues({
        ...selectedValues,
        [`selected_${obj.name}`]: selectedOption,
      });
    }
  };
  return (
    <>
      <IndexNavbar defaultColor={false} />

      {data && Array.isArray(data.dataset)? (
        
        <div className="main">
          <DocumentListing
            onFindResults={onFilterRecords}
            data={data}
            projectsOptions={projectOptions}
            onSort={onSort}
            filtercount={filtercount}
            onResetFilters={onResetFilters}
            orderType={orderType}
            filtersList={formField}
            formField={formField}
            loader={loader}
            pageno={page_no}
            onDDChange={handelDropDownChange}
            selectedValues={selectedValues}
            onFormFieldChange={onFormFieldChange}
            sortingValue={sortingValue}
            onCurrentPageChange={handleCurrentPageChange}
          />
          <Footer />
        </div>
      ) : (
        <HomePage
          projectsOptions={projectOptions}
          onFindResults={getAllDocuments}
          formField={formField}
          loader={loader}
          onFormFieldChange={onFormFieldChange}
          onResetFilters={onResetFilters}
          selectedValues={selectedValues}
          onDDChange={handelDropDownChange}
        />
      )}
    </>
  );
};

export default Main;
