import React, { useEffect, useMemo, useRef, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Button, Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import Select from "react-select";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { BsFullscreen } from "react-icons/bs";
import { BiReset, BiRotateLeft, BiRotateRight } from "react-icons/bi";
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineRotateLeft,
  AiOutlineRotateRight,
  AiOutlineVerticalLeft,
  AiOutlineVerticalRight,
} from "react-icons/ai";
import { HiMagnifyingGlassPlus, HiMagnifyingGlassMinus } from "react-icons/hi2";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { BASE_URL } from "utils/apiUrls";
import { FaHandPaper } from "react-icons/fa";
import { GiArrowCursor } from "react-icons/gi";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import { useHistory } from "react-router-dom";

const percentages = [
  { label: "10%", value: 10 },
  { label: "25%", value: 25 },
  { label: "50%", value: 50 },
  { label: "75%", value: 75 },
  { label: "100%", value: 100 },
];

export default function PDFComponent({ payload, pageno, divHeight, pdf }) {
  const [numPages, setNumPages] = useState(1);
  const [pointer, setPointer] = useState(true);
  const [showDetail, setShowDetail] = useState(false);
  const [fullscreen, setFullScreen] = useState(false);
  const [pageNumber, setPageNumber] = useState(pageno);
  const [inputPageNo, setInputPageNo] = useState(1);
  const [rotate, setRotate] = useState(0);
  let [counter, setCounter] = useState(0);
  const [scale, setScale] = useState(1);
  const [renderNavButtons, setRenderNavButtons] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: null,
    width: null,
  });
  const [selectedOption, setSelectedOption] = useState(percentages[4]);
  const [toolkit, setToolkit] = useState({
    panZoom: { disabled: false },
    panning: { lockAxisY: true, lockAxisX: true },
  });
  const screen1 = useFullScreenHandle();
  const ref = useRef(null);
  const inputRef = React.useRef(null);

  const history = useHistory();

  const customStyles = {
    control: (base) => ({
      ...base,
      color: "#000000",
      minHeight: "24px",
      border: "none",
      boxShadow: "none",
    }),
    option: (provided) => ({
      ...provided,
      color: "#000000",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000000",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "24px",
    }),
  };
  useEffect(() => {
    setTimeout(() => {
      if (pageno > 1) {
        setInputPageNo(pageno);
        setPageNumber(parseInt(pageno));
      }
    }, 500);
  }, [pageno]);
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        if (fullscreen) {
          onrestvalues();
        }
      }
    };

    document.addEventListener("fullscreenchange", keyDownHandler);

    // 👇️ clean up event listener
    return () => {
      document.removeEventListener("fullscreenchange", keyDownHandler);
    };
  }, [fullscreen]);
  const onrestvalues = () => {
    setFullScreen(false);
    setScale(1);
    inputRef.current.click();
  };
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevValue = usePrevious(pageno);
  const elementObserver = useMemo(() => {
    return new ResizeObserver(() => {
      debounce(() => {
        if (!ref.current) return;
        setDimensions({
          height: ref.current.clientHeight,
          width: ref.current.clientWidth,
        });
        divHeight(ref.current.clientHeight);
      }, 1000)();
    });
  }, [ref.current]);
  useEffect(() => {
    if (!ref) return;
    const element = ref.current;
    elementObserver.observe(element);
    if (prevValue?.pageno !== pageno) {
      setInputPageNo(pageno);
      setPageNumber(parseInt(pageno));
    }
    return () => {
      elementObserver.unobserve(element);
    };
  }, [elementObserver, prevValue?.pageno, pageno]);
  const debounce = (fn, ms) => {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  };
  const onSuccess = () => {
    setPageNumber(1);
    setInputPageNo(1);
    setRenderNavButtons(true);
  };
  const onSelection = () => {
    setPointer(!pointer);
  };
  const rotatePdf = (val) => {
    let degree;
    if (val > 3 || val < -3) {
      degree = 0;
    } else {
      degree = val;
    }
    setCounter(degree);
    switch (degree) {
      case 0:
        setRotate(0);
        break;
      case 1:
        setRotate(90);
        break;
      case 2:
        setRotate(180);
        break;
      case 3:
        setRotate(270);
        break;
      case -1:
        setRotate(90);
        break;
      case -2:
        setRotate(180);
        break;
      case -3:
        setRotate(270);
        break;
      default:
        setRotate(0);
        break;
    }
  };
  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
    setInputPageNo(pageNumber - 1);
  };
  const firstPage = () => {
    setInputPageNo(1);
    setPageNumber(1);
  };
  const lastPage = () => {
    setInputPageNo(numPages);
    setPageNumber(numPages);
  };
  const nextPage = () => {
    changePage(+1);
    setInputPageNo(pageNumber + 1);
  };
  const onKeyUp = (e) => {
    const newValue = e.target.value;

    if (newValue > 0 && newValue && newValue <= numPages) {
      setInputPageNo(newValue);
      setPageNumber(parseInt(newValue));
    } else {
      setInputPageNo("");
    }
  };

  const onFullScreen = () => {
    if (!fullscreen) {
      setFullScreen(true);
      setScale(0.5);
      screen1.enter();
    } else {
      screen1.exit();
      setScale(1);
      inputRef.current.click();
      setFullScreen(false);
    }
  };
  function truncate(str){
    return (str && str.length > 15) ? str.slice(0, 15-1) + '...' : str;
  };
  return (
    <>
      <Row className="head m-0">
        <Col>
          <p className="mt-3" style={{ fontSize: "18px" }}>
            <span
              className="home pointer-hand"
              onClick={() => {
                history.push("/index");
              }}
            >
              Home /
            </span>
            <span
              className="home pointer-hand"
              onClick={() => {
                history.push("/index");
              }}
            >
              {" "}
              Search Results{" "}
            </span>
            <span>
              /{" "}
              {`${payload?.project_name} (${payload?.supplier_name}) - ${payload?.project_number} ${payload?.descipline}`}
            </span>
          </p>
          <h3 className="pdf_heading font-weight-bold ">
            {" "}
            {`${payload?.project_name} (${payload?.supplier_name}) - ${payload?.project_number} ${payload?.descipline}`}
          </h3>
        </Col>
        <Col>
          <Button
            onClick={() => setShowDetail(!showDetail)}
            className="header-buttons mt-4  float-right"
            outline
          >
            {showDetail ? "Less Info" : "More Info"}{" "}
            {showDetail ? (
              <GoTriangleUp size={20} />
            ) : (
              <GoTriangleDown size={20} />
            )}
          </Button>
        </Col>
      </Row>
      {showDetail ? (
        <Row className="ml-0 mr-0">
          <Col className="p-0" md={12}>
            <Card className="card-plain mb-0 ">
              <CardBody style={{ marginBottom: 0 }}>
                <Row>
                  <Col className="mb-2 p-0" lg={3} md={3} sm={12}>
                    <div className="detail_header">
                      <span className="pr-2 text-left detail_info_heading ">
                        Supplier:
                      </span>{" "}
                      &nbsp;
                      <label className="detail_info_label">
                        {truncate(payload?.supplier_name)}
                      </label>
                    </div>
                  </Col>
                  <Col className="mb-2 p-0" lg={3} md={3} sm={12}>
                    <div className="detail_header ">
                      <span className="pr-2 detail_info_heading ">
                        {" "}
                        Project:
                      </span>{" "}
                      &nbsp;
                      <label className="detail_info_label">
                        {truncate(payload?.project_name)}
                      </label>
                    </div>
                  </Col>
                  <Col className="mb-2 p-0" lg={3} md={3} sm={12}>
                    <div className="detail_header">
                      <span className="pr-2 detail_info_heading ">
                        Document Revised:
                      </span>{" "}
                      &nbsp;
                      <label className="detail_info_label">
                        {truncate(payload?.document_revised)}
                      </label>
                    </div>
                  </Col>
                  <Col className="mb-2 p-0" lg={3} md={3} sm={12}>
                    <div className="detail_header">
                      <span className="pr-2 detail_info_heading ">
                        Project No.:
                      </span>{" "}
                      &nbsp;
                      <label className="detail_info_label">
                        {truncate(payload?.project_number)}
                      </label>
                    </div>
                  </Col>
                  <Col className="mb-2 p-0" lg={3} md={3} sm={12}>
                    <div className="detail_header">
                      <span className="pr-2 detail_info_heading ">
                        Discipline:
                      </span>{" "}
                      &nbsp;
                      <label className="detail_info_label">
                        {truncate(payload?.operation)}
                      </label>
                    </div>
                  </Col>
                  <Col className="mb-2 p-0" lg={3} md={3} sm={12}>
                    <div className="detail_header">
                      <span className="pr-2 detail_info_heading ">
                        Activity No.:
                      </span>{" "}
                      &nbsp;
                      <label className="detail_info_label">
                        {truncate(payload?.activity_number)}
                      </label>
                    </div>
                  </Col>
                  <Col className="mb-2 p-0" lg={3} md={3} sm={12}>
                    <div className="detail_header">
                      <span className="pr-2 detail_info_heading ">
                        Operation:
                      </span>{" "}
                      &nbsp;
                      <label className="text-left detail_info_label">
                        {truncate(payload?.operation)}
                      </label>
                    </div>
                  </Col>
                  <Col className="mb-2 p-0" lg={3} md={3} sm={12}>
                    <div className="detail_header">
                      <span className="pr-2 detail_info_heading ">Part</span>{" "}
                      &nbsp;
                      <label className="detail_info_label">
                        {truncate(payload?.sub_part)}
                      </label>
                    </div>
                  </Col>

                  <Col className="mb-2 p-0" lg={3} md={3} sm={12}>
                    <div className="detail_header">
                      <span className="pr-2 detail_info_heading ">
                        File Tracking No.:
                      </span>{" "}
                      &nbsp;
                      <label className="detail_info_label">
                        {truncate(payload?.file_tracking_number)}{" "}
                      </label>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : null}
      <div className="pdf_main">
        <div className={`${!pointer ? "pan-zoom" : ""}`} ref={ref}>
          <Document
            pageLayout={"twoColumnLeft"}
            pageMode={"useThumbs"}
            file={BASE_URL + "/" + pdf}
            onLoadSuccess={({ numPages }) => {
              setNumPages(numPages);
              setRenderNavButtons(true);
              onSuccess();
            }}
          >
            <div style={{ overflow: "hidden" }}>
              <TransformWrapper
                panning={pointer ? toolkit.panning : null}
                wheel={toolkit.panZoom}
                defaultPositionX={200}
                defaultPositionY={100}
              >
                {({
                  zoomIn,
                  zoomOut,
                  resetTransform,
                  positionX,
                  positionY,

                  ...rest
                }) => (
                  <FullScreen handle={screen1}>
                    {/* {fullscreen && scale ? "" : resetTransform()} */}
                    <div style={{ textAlign: "-webkit-center" }}>
                      {renderNavButtons && (
                        <div className="disable-text-selection  pdf-filter">
                          <div className="d-inline">
                            {pageNumber <= 1 ? (
                              <AiOutlineVerticalRight
                                className=" mr-2"
                                color="#000000"
                                size={18}
                              />
                            ) : (
                              <AiOutlineVerticalRight
                                className=" mr-2"
                                color="#000000"
                                size={18}
                                onClick={firstPage}
                              />
                            )}
                            {pageNumber <= 1 ? (
                              <AiOutlineLeft className="pdf_icons" size={23} />
                            ) : (
                              <AiOutlineLeft
                                size={23}
                                className="mr-2 pdf_icons"
                                disabled={pageNumber <= 1}
                                onClick={previousPage}
                              />
                            )}
                            <Input
                              className="filter-input d-inline ml-2 mt-1"
                              value={inputPageNo}
                              onChange={(e) => onKeyUp(e)}
                            />
                            <label
                              style={{ color: "black" }}
                              className="mt-2 ml-1"
                            >{`/ ${numPages}`}</label>
                            {pageNumber !== numPages ? (
                              <AiOutlineRight
                                disabled={pageNumber === numPages}
                                onClick={nextPage}
                                className="ml-2 mr-2 pdf_icons"
                                size={23}
                              />
                            ) : (
                              <AiOutlineRight
                                className="ml-2 mr-2 pdf_icons"
                                size={23}
                              />
                            )}
                            {pageNumber !== numPages ? (
                              <AiOutlineVerticalLeft
                                size={18}
                                color="black"
                                onClick={lastPage}
                              />
                            ) : (
                              <AiOutlineVerticalLeft color="black" size={18} />
                            )}
                          </div>
                          <div className="d-flex">
                            {/* <Select
                          isSearchable={false}
                          name="sort"
                          className=" float-right mt-1  mr-2"
                          styles={customStyles}
                          customStyles={customStyles}
                          components={{ IndicatorSeparator: () => null }}
                          onChange={handleDDChange}
                          value={selectedOption}
                          options={percentages}
                        />
                        <TbZoomPan
                          className="mt-1 mr-3 "
                          color={
                            toolkit.panZoom.disabled
                              ? "rgba(227,22,91,.7)"
                              : "#2f8adb"
                          }
                          size={22}
                          onClick={() =>
                            setToolkit({
                              panZoom: { disabled: !toolkit.panZoom.disabled },
                              panning: {lockAxisX:true, lockAxisY:true}
                            })
                          }
                        /> */}

                            <div
                              className={`action_buttons ${
                                pointer ? "content-bg-2" : "content-bg-1"
                              }`}
                            >
                              <GiArrowCursor
                                className="mr-2 "
                                color={`${
                                  !pointer ? "#36454F" : "rgb(0 0 0 / 54%)"
                                }`}
                                size={18}
                                onClick={onSelection}
                              />
                              <FaHandPaper
                                color={`${
                                  pointer ? "#36454F" : "rgb(0 0 0 / 54%)"
                                }`}
                                size={18}
                                onClick={onSelection}
                              />
                            </div>
                            <div className="action_buttons">
                              <HiMagnifyingGlassPlus
                                color="rgb(0 0 0 / 54%)"
                                className="m-1"
                                size={20}
                                onClick={() => zoomIn()}
                              />
                              <input
                                id="reset"
                                ref={inputRef}
                                className="m-1"
                                style={{display:'none'}}
                                onClick={() => resetTransform()}
                              />
                              <HiMagnifyingGlassMinus
                                color="rgb(0 0 0 / 54%)"
                                className="m-1"
                                size={20}
                                onClick={() => zoomOut()}
                              />
                              <BsFullscreen
                                className=" ml-1 "
                                color="rgb(0 0 0 / 54%)"
                                size={16}
                                onClick={onFullScreen}
                              />
                            </div>
                            <div className="action_buttons">
                              <BiRotateLeft
                                className="m-1"
                                size={20}
                                color="rgb(0 0 0 / 54%)"
                                onClick={() => rotatePdf(--counter)}
                              />
                              <BiRotateRight
                                className="m-1"
                                color="rgb(0 0 0 / 54%)"
                                size={20}
                                onClick={() => rotatePdf(++counter)}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <TransformComponent>
                        <Page
                          scale={scale}
                          rotate={rotate}
                          heigh={dimensions.height}
                          width={dimensions.width}
                          pageNumber={pageNumber}
                        />
                      </TransformComponent>
                    </div>
                  </FullScreen>
                )}
              </TransformWrapper>
            </div>
          </Document>
        </div>
      </div>
    </>
  );
}
