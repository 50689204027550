import { ApiUrls } from "../../../utils/apiUrls";
// import authAxios from "../../services/axiosInstance";
import axios from "axios";
import authAxios from "utils/axios-instance";
export const getAllDocumentsList = async (
  per_page,
  page_no,
  order_type,
  order_by,
  project_name,
  keyword,
  project_number,
  descipline,
  activity_number,
  operation
) => {
  try {
    const { data } = await authAxios.get(ApiUrls.Document.GET_DOCUMENT_LISTING, {
      params: {
        per_page:12,
        page_no,
        order_type ,
        order_by ,
        project_name,
        keyword,
        project_number,
        descipline,
        activity_number,
        operation,
      },
    });
    return data;
  } catch (response) {
    return response;
  }
};

export const getDocumentById = async (id) => {
  try {
    const { data } = await authAxios.get(ApiUrls.Document.DETAIL_VIEW(id));
    return data;
  } catch (response) {
    return response;
  }
};
export const getPDFFileById = async (id) => {
  try {
    const { data } = await authAxios.get(ApiUrls.Document.GET_PDF_FILE(id));
    return data;
  } catch (response) {
    return response;
  }
};
  export const uploadPDFFIle = async (data, onUploadProgress) => {
    try {
      const { data: data_1 } = await authAxios.post(
        ApiUrls.Document.UPLOAD_PDF_FILE,
        data,{
        onUploadProgress}
      );
      return data_1;
    } catch (response) {
      return response;
    }
  };
export const AddDocument = async (data) => {
  try {
    const { data: data_1 } = await authAxios.post(
      ApiUrls.Document.ADD_DOCUMENT,
      data
    );
    return data_1;
  } catch (response) {
    return response;
  }
};
export const getAllProjectsList = async (data) => {
  try {
    const { data: data_1 } = await authAxios.get(
      ApiUrls.Lookups.GET_PROJECTS_LIST,
      data
    );
    return data_1;
  } catch (response) {
    return response;
  }
};
export const getHighlightedWords = async (id, query) => {
  try {
    const { data } = await authAxios.get(ApiUrls.Document.GET_HIGHLIGHTED_WORDS, {
      params: {
        id: id,
        query: query,
      },
    });
    return data;
  } catch (response) {
    return response;
  }
};
