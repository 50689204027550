import React, { useEffect, useState } from "react";
import Main from "views/Main";
import AddDocument from "views/forms/Add-Document";
import ProfilePage from "views/forms/ProfilePage.js";
import RegisterPage from "views/forms/RegisterPage.js";
import PDFViewer from "views/index-sections/PDF/PDFViewer";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import EventBus from "common/EventBus";
import { useHistory } from "react-router-dom";
import SectionLogin from "views/index-sections/SectionLogin";

import AuthService from "services/auth.service";
export default function App() {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const navigate = useHistory();

  useEffect(() => {
    const authenticated = AuthService.isAuthenticated();
    if (authenticated) {
      navigate.push("/index");
    } else {
      navigate.push("/login");
    }
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
  };
  return (
    <Switch>
      <Route path="/index" render={(props) => <Main {...props} />} />

      <Route
        path="/add-document"
        render={(props) => <AddDocument {...props} />}
      />
      <Route path="/pdf-viewer/:id/:query?/">
        <PDFViewer />
      </Route>
      <Route
        path="/profile-page"
        render={(props) => <ProfilePage {...props} />}
      />
      <Route path="/login" render={(props) => <SectionLogin {...props} />} />
      <Route
        path="/register-page"
        render={(props) => <RegisterPage {...props} />}
      />
      <Redirect to="/index" />
    </Switch>
  );
}
