export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const LOGIN = `${BASE_URL}/user/auth/login/`;
export const LOGIN_ACCESS_TOKEN = `${BASE_URL}/user/auth/token/refresh/`;
export const LOG_OUT = `${BASE_URL}/account/logout`;
export const FORGOT_PASSWORD = `${BASE_URL}/user/auth/change_password/`;
export const ApiUrls = {


  
  Document: {
    GET_DOCUMENT_LISTING: `${BASE_URL}/search/list/`,
    ADD_DOCUMENT: `${BASE_URL}/document/upload/`,
    DETAIL_VIEW:(id)=>`${BASE_URL}/document/document_meta/${id}`,
    GET_PDF_FILE:(id)=>`${BASE_URL}/document/document/${id}`,
    GET_HIGHLIGHTED_WORDS:`${BASE_URL}/search/document/`,
    UPLOAD_PDF_FILE:`${BASE_URL}/document/file_upload/`
  },
  Lookups:{
    GET_PROJECTS_LIST: `${BASE_URL}/document/project_list/`
  }
  }