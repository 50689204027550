/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import {
  AiFillCaretDown,
  AiFillQuestionCircle,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from "react-icons/ai";
import Select, { components } from "react-select";
import "./header.scss";
import { RiSearchLine } from "react-icons/ri";

function HomePage({
  onFindResults,
  formField,
  onFormFieldChange,
  onResetFilters,
  onDDChange,
  selectedValues,
  projectsOptions,
  loader
}) {
  const [expandOptions, updateOptions] = useState(false);
  const cardRef = useRef(null);
  const contentRef = useRef(null);
  const { selected_project_name } = selectedValues;
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <AiFillCaretDown color="black" />
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    if (expandOptions) {
      cardRef.current.style.height =
        contentRef.current.getBoundingClientRect().height + "px";
    } else {
      cardRef.current.style.height = "0px";
    }
  }, [expandOptions]);
  const style = {
    control: (base) => ({
      ...base,
      border: "none",
      minHeight: 35,
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const searchKey = (e) => {
    if (e.keyCode === 13) {
      onFindResults(formField);
    }
  };
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage: "url(" + require("assets/img/homepage-bg.jpg") + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <h2 className="presentation-subtitle text-center">
                Welcome to ReaDoc - A Digital Library for Machine Manuals
              </h2>
              <p className="mt-3 mb-5">
                Enter your keywords or apply filters to begin search
              </p>
              <Row>
                <Col className="ml-auto mr-auto">
               
                  <Form>
                    <Card
                      className="page-carousel home-filters pl-4 pr-4 pt-3 pb-0"
                      style={{ border: "rgba(34, 34, 34, 0.85) 2px solid" }}
                    > {loader  ? 
                      <div className="loader-position">
                        <div className="ld-loader ld-book">
                          <figure className="ld-page"></figure>
                          <figure className="ld-page"></figure>
                          <figure className="ld-page"></figure>
                        </div>
                      </div> : null}
                      <Row className="">
                          <Col sm={12} md="3" className="p-1">
                            <div
                              className="label-dropbox  "
                              onClick={() => updateOptions(true)}
                            >
                              {/* <label className="label-header">Project Name</label> */}
                              <Select
                              id="select-projects"
                                isSearchable={false}
                                name="project_name"
                                className="dropdown"
                                components={{ DropdownIndicator }}
                                styles={style}
                                onClick={() => updateOptions(true)}
                                value={selected_project_name}
                                onChange={(selectedOption, name) => onDDChange(selectedOption, name)}
                                options={projectsOptions}
                                classNamePrefix="react-select" />
                            </div>
                          </Col>

                          <Col className="p-1" sm={12} md="9">
                            <InputGroup className="has-danger">
                              <Input
                                name="keyword"
                                value={formField.keyword || ""}
                                className="form-control-danger"
                                id="input-keyword"
                                placeholder="Search for keyword..."
                                type="text"
                                onKeyDown={(e) => {
                                  searchKey(e);
                                }}
                                maxLength={100}
                                onClick={() => updateOptions(true)}
                                onChange={onFormFieldChange} />
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText
                                  style={{
                                    borderRadius: "0px 5px 5px 0",
                                    border: " #8C0001 1px solid",
                                  }}
                                  className="bg-stech pointer-hand"
                                >
                                  <RiSearchLine
                                  id="search-btn"
                                    color="#fff"
                                    size={25}
                                    onClick={() => onFindResults(formField)} />
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                        </Row><Row>
                            <div className={"widget"} ref={cardRef}>
                              <div style={{ display: "flex" }} ref={contentRef}>
                                <Col md="3" className="p-1">
                                  <label className="label-header">Project No</label>
                                  <div className="label-dropbox ">
                                    <Input
                                      id="project-no"
                                      style={{ border: "none" }}
                                      name="project_number"
                                      maxLength={30}
                                      value={formField.project_number || ""}
                                      placeholder="1872"
                                      type="text"
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      } }
                                      onChange={onFormFieldChange} />
                                  </div>
                                </Col>
                                <Col md="3" className="p-1">
                                  <label className="label-header">Discipline</label>
                                  <div className="label-dropbox ">
                                    <Input
                                      id="discipline"
                                      style={{ border: "none" }}
                                      name="descipline"
                                      maxLength={30}
                                      value={formField.descipline || ""}
                                      placeholder="Process Engineering"
                                      type="text"
                                      onChange={onFormFieldChange} />
                                  </div>
                                </Col>
                                <Col md="3" className="p-1">
                                  <label className="label-header">Activity</label>
                                  <div className="label-dropbox ">
                                    <Input
                                    id="activity"
                                      style={{ border: "none" }}
                                      name="activity_number"
                                      maxLength={30}
                                      value={formField.activity_number || ""}
                                      placeholder="B1-C2"
                                      type="text"
                                      onChange={onFormFieldChange} />
                                  </div>
                                </Col>
                                <Col md="3" className="p-1">
                                  <label className="label-header">Operation</label>
                                  <div className="label-dropbox ">
                                    <Input
                                    id="operation"
                                      style={{ border: "none" }}
                                      className=""
                                      maxLength={30}
                                      name="operation"
                                      value={formField.operation || ""}
                                      placeholder="Operating Manual"
                                      type="text"
                                      onChange={onFormFieldChange} />
                                  </div>
                                </Col>
                              </div>
                            </div>
                          </Row><Row>
                            <Col>
                              <label
                                onClick={() => updateOptions(!expandOptions)}
                                className="label-search float-left"
                              >
                                {expandOptions ? (
                                  <AiOutlineCaretUp
                                  id="less-filter"
                                    size={15}
                                    className="mr-1"
                                    color="white" />
                                ) : (
                                  <AiOutlineCaretDown
                                  id="more-filter"
                                    size={15}
                                    className="mr-1"
                                    color="white" />
                                )}
                                {expandOptions ? "Less Filters" : "More Filters"}
                              </label>

                              <div className="vl"></div>
                              <label
                              id="reset-filter"
                                onClick={onResetFilters}
                                className="label-search float-left"
                              >
                                Reset Search
                              </label>
                            </Col>
                          </Row>
                    </Card>
                  </Form>

                </Col>
              </Row>
              <div className="fog-low"></div>
              <div className="fog-low right"></div>
            </div>
          </Container>
        </div>
        <div className="info-btn-bottom">
          <Button
            className="btn btn-round how-to-useBtn"
            color="secondary"
            //  onClick={() => navigate.push("/add-document")}
            target="_blank"
          >
            <AiFillQuestionCircle size={20} className="mb-1 mr-2" /> How To Use
          </Button>
        </div>

        <div
          className="moving-clouds"
          style={{
            backgroundImage: "url(" + require("assets/img/clouds.png") + ")",
          }}
        />
        {/* <h3 className="main-page-logo font-weight-bold ">
          <span className="powered-by">Powered by </span>
          <a href="https://stech.ai" target="_blank">
            <img
              alt="Stech.ai"
              className="invision-logo"
              src={
                "https://sviz.stech.ai/static/media/Stech.2482205997d4d7b684562435f36200f2.svg"
              }
            />
          </a>
        </h3> */}
      </div>
    </>
  );
}

export default HomePage;
