import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Input,
  Label,
} from "reactstrap";
import PDFComponent from "../PDF/PDFComponent";
import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import {
  getDocumentById,
  getHighlightedWords,
  getPDFFileById,
} from "../DocumentListing/apiCalls";
import { useParams } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";

// core components

function PDFViewer() {
  const { id, query } = useParams();
  const [payload, setPayload] = useState();
  const [words, setWords] = useState({ words: [], page_number: [] });
  const [pdf, setPdf] = useState();
  const [height, setHeight] = useState(500);
  const [keyword, setKeyword] = useState(query ? query : "");
  const [pageno, setPageNo] = useState(1);


  const set = keyword !== "" ? keyword : query === undefined ? "" : query;
  useEffect(() => {
    getAllDocuments(id);
    getPDFById(id);
    getWords(id, query);
    // onDocumentLoadSuccess()
  }, []);

  const getAllDocuments = (id) => {
    getDocumentById(id).then((response) => {
      if (response.code === 200) {
        setPayload(response.data[0]);
      }
    });
  };
  const onEnterPress = (event) => {
    if (event.keyCode === 13) {
      getWords(id, set);
    }
  };
  const onRemoveKeyword = () => {
   if(query===keyword ){
    setKeyword("");
    return;
   }
    setKeyword(query ? query : "");
    getWords(id, query);
  };
  const getPDFById = (id) => {
    getPDFFileById(id).then((response) => {
      if (response.code === 200) {
        setPdf(response.data[0].file_path);
      }
    });
  };
  const getWords = (id, query) => {
    if (set) {
      getHighlightedWords(id, query).then((response) => {
        if (response.code === 200) {
          const {dataset} = response.data;
          let result = dataset && dataset.map((a) => {
            // let totalchar = a.page_text.length;
            let position = a.page_text.indexOf(set);
            let string = a.page_text.substring(position, position + 100);
            return string;
          });
          let pages = dataset.map((a) => a.page_number);
          setWords({ ...words, words: result, page_number: pages });
          setPageNo(pages[0]);
        }
      });
    }
  };
  const onCardClick = (e) => {
    setPageNo(e);
  };
  const getHeightOfPage = (e) => {
    setHeight(e);
  };
  const onKeywordChange =(e)=>{
    if((e && e.target.value !== "") || keyword.length === 1){
    setKeyword(e.target.value);
    }
    else{
      setKeyword(set)
    }
  }
  const redlement = (
    <small>
      {" "}
      <span className="stech-text font-weight-bold">{`${set} occurrences`}</span>
    </small>
  );
  return (
    <>
      <div className="section mt-2 ">
        <IndexNavbar defaultColor={true} />
        <Container className="bg-light border " fluid>
          <Row>
      
            <Col className="pl-0 pr-0" md={3}>
              <Card className="detail-card pl-3  mt-3">
                <Row>
                  <div  className=" custom-scroll">
                    <Col className="p-0" style={{ height: height< 150 ? 150 : height }}>
                      <FormGroup className=" sticky-box  pl-2 pr-2 ">
                        <h4 className="description">Search</h4>
                        <AiFillCloseCircle
                        className="pointer-hand"
                          onClick={() => onRemoveKeyword()}
                          color="#999999"
                          style={{
                            position: "absolute",
                            top: 33,
                            right: 20,
                          }}
                          size={20}
                        />
                        <Input
                          className=" mb-2"
                          placeholder="Keyword "
                          maxLength={50}
                          value={keyword}
                          onChange={(e) => onKeywordChange(e)}
                          onKeyDown={(e) => onEnterPress(e)}
                          type="text"
                        >
                          {" "}
                        </Input>

                        {/* <h6 className="d-inline">
                          Found {redlement} in document
                        </h6> */}
                      </FormGroup>

                      {words.words.length > 0 ? (
                        words.words.map((item, index) => {
                          if (
                            item
                              .toLocaleLowerCase()
                              .includes(set?.toLowerCase())
                          ) {
                            let b = set?.toLocaleLowerCase();
                            var mapObj = {
                              [b]: `<span class="bg-warning stech-text">${set}</span>`,
                            };
                            var re = new RegExp(
                              Object.keys(mapObj).join("|"),
                              "gi"
                            );
                            item = item.replace(re, function (matched) {
                              return mapObj[matched.toLowerCase()];
                            });
                          }

                          return (
                            <>
                              <Card
                                className={`card-plain pointer-hand ${
                                  pageno === words.page_number[index]
                                    ? "bg-gray"
                                    : null
                                } `}
                                onClick={() =>
                                  onCardClick(words.page_number[index])
                                }
                              >
                                <h4 className="description">
                                  Page {words.page_number[index]}{" "}
                                </h4>

                                <div
                                  className="small_text"
                                  dangerouslySetInnerHTML={{ __html: item }}
                                />
                              </Card>
                            </>
                          );
                        })
                      ) : (
                        <p className="p-3">No Matching Words</p>
                      )}
                    </Col>
                  </div>
                </Row>
              </Card>
            </Col>
            <Col className="pl-0 pr-0" md={9}>
            
                <PDFComponent
                payload={payload}
                  pdf={pdf}
                  pageno={pageno}
                  divHeight={getHeightOfPage}
                />
            </Col>
          </Row>
        </Container>
      </div>
      <div>
      
      </div>
      <Footer position={'relative'} />
    </>
  );
}

export default PDFViewer;
