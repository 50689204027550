import React, { useState, useRef } from "react";
import { AiFillLock } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useHistory } from "react-router-dom";

import { AiFillEyeInvisible } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
// reactstrap components
import {
  Button,
  Card,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import AuthService from "services/auth.service";

// core components

function SectionLogin() {
  let navigate = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(
    "Invalid Credentials or Connection Lost !"
  );

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [showMessage, setShowMessage] = useState(false);

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    // setLoading(true);

    // if (checkBtn.current.context._errors.length === 0) {
    AuthService.login(username, password).then(
      (response) => {
        navigate.push("/");
        setShowMessage(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        // setMessage(resMessage);
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 4000);
        clearTimeout();
      }
    );
    // } else {
    //   setLoading(false);
    // }
  };
  const authenticated = AuthService.isAuthenticated();
    if (authenticated) {
      navigate.push("/index");
    }
    
  return (
    <>
      <div
        className="section section-image section-login vh-100  "
        style={{
          backgroundImage: "url(" + require("assets/img/homepage-bg.jpg") + ")",
        }}
      >
        <Container className="mt-5">
          <Row>
            <Col className="mx-auto" lg="4" md="6">
              <Card className="card-register">
                <h3 className="title font-weight-bold mx-auto">
                  Welcome To Doc-Reader
                </h3>

                <Form className="register-form" onSubmit={handleLogin}>
                  <label>Username</label>
                  <InputGroup className="form-group-no-border">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FaUserAlt size={20} />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      id="username"
                      placeholder="Username"
                      type="text"
                      value={username}
                      onChange={onChangeUsername}
                      required
                    />
                  </InputGroup>
                  <label>Password</label>
                  <InputGroup className="form-group-no-border">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <AiFillLock size={20} />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      id="password"
                      placeholder="Password"
                      type={passwordShown ? "text" : "password"}
                      value={password}
                      onChange={onChangePassword}
                      required
                    />
                  </InputGroup>
                  {passwordShown ? (
                    <AiFillEye
                      className="eyeIcon"
                      size={22}
                      color="#495057"
                      onClick={togglePassword}
                    />
                  ) : (
                    <AiFillEyeInvisible
                      className="eyeIcon"
                      size={22}
                      color="#495057"
                      onClick={togglePassword}
                    />
                  )}
                  <Button
                    id="login-btn"
                    block
                    className="btn-round"
                    style={{ background: "#8C0001" }}
                    type="submit"
                    // onClick={handleLogin}
                  >
                    Login
                  </Button>
                </Form>
              </Card>

              {showMessage ? (
                <>
                  <p className="p-2 error-message  login-message-fonts">
                    {message}
                    <MdClose
                      className="float-right mb-1"
                      size={22}
                      onClick={() => setShowMessage(!showMessage)}
                      color="white"
                    />
                  </p>
                </>
              ) : null}
            </Col>
          </Row>
        </Container>
        <div />
        <h3 className="main-page-logo font-weight-bold ">
          <span className="powered-by">Powered by </span>
          <a href="https://stech.ai" target="_blank">
            <img
              alt="Stech.ai"
              className="invision-logo"
              src={
                "https://sviz.stech.ai/static/media/Stech.2482205997d4d7b684562435f36200f2.svg"
              }
            />
          </a>
        </h3>
      </div>
    </>
  );
}

export default SectionLogin;
