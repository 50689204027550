import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Progress,
} from "reactstrap";
import { AiFillCloseCircle, AiOutlineProject } from "react-icons/ai";
import { BsFillCheckCircleFill, BsSubtract } from "react-icons/bs";
import { HiDocumentText } from "react-icons/hi";
import { IoBarcodeSharp } from "react-icons/io5";
import { GiPlayerPrevious } from "react-icons/gi";
import { BsFileEarmarkCheck } from "react-icons/bs";
import DragDrop from "./DragDrop";
import { VscSymbolProperty } from "react-icons/vsc";
import "../../assets/css/DragDrop.css";
import { TbNumbers, TbActivity } from "react-icons/tb";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// core components
import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import {
  AddDocument,
  uploadPDFFIle,
} from "../index-sections/DocumentListing/apiCalls";

import { useHistory } from "react-router-dom";
import { GoFileDirectory } from "react-icons/go";
import { MdDelete, MdOutlineFolderOpen } from "react-icons/md";
function LandingPage() {
  const [pdfuploaded, pdfUploaded] = useState(false);
  const [onSubmitted, updateOnSubmitted] = useState(false);
  const [isUploaded, updateIsUploaded] = useState(false);
  const [filename, updateFileName] = useState("");
  const [file_url, updateFileURL] = useState("");
  const history = useHistory();
  const [progress, setProgress] = useState(0);
  const [formField, updateFormField] = useState({
    project_name: "",
    project_number: "",
    descipline: "",
    activity_number: "",
    operation: "",
    sub_part: "",
    supplier_name: "",
    document_revised: "",
    file_tracking_number: "",
  });

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  const onFileChange = (e) => {
    setProgress(0);
    pdfUploaded(true);
    if (e.target.files && e.target.files[0]) {
      updateFileName(e.target.files[0].name);
      const data = new FormData();
      data.append("file", e.target.files[0]);
      uploadPDFFIle(data, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      })
        .then((response) => {
          if (response.code === 200) {
            updateIsUploaded(true);
            pdfUploaded(false);
            updateFileURL(response.data.file_path);
          }
        })
        .catch(() => {
          setProgress(0);
        });
    } else if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      updateFileName(e.dataTransfer.files[0].name);

      const data = new FormData();
      data.append("file", e.dataTransfer.files[0]);
      uploadPDFFIle(data, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      }).then((response) => {
        if (response.code === 200) {
          updateIsUploaded(true);
          pdfUploaded(false);
          updateFileURL(response.data.file_path);
        }
      });
    }
  };
  const showToastMessage = (message, action) => {
    if (action === "success") {
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (action === "error") {
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const validateField = ({ name, value }) => {
    updateFormField({
      ...formField,
      [name]: value,
    });
  };
  const onFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "project_name" ||
      "project_number" ||
      name === "descipline" ||
      "activity_number" ||
      name === "operation" ||
      name === "sub_part" ||
      "document_revised" ||
      name === "file_tracking_number" ||
      name === "supplier_name"
    ) {
      validateField({ name, value });
      return;
    }
  };
  const onDelete = () => {
    pdfUploaded(false);
    updateFileURL("");
    updateFileName("");
    updateIsUploaded(false);
    setProgress(0);
  };
  const onSubmitData = (e) => {
    const {
      project_name,
      project_number,
      descipline,
      activity_number,
      operation,
      supplier_name,
      sub_part,
      document_revised,
      file_tracking_number,
    } = formField;
    if (project_name === "") {
      showToastMessage("Add Project Name", "error");
      return;
    } else if (project_number === "") {
      showToastMessage("Add Project Number", "error");
      return;
    } else if (descipline === "") {
      showToastMessage("Add Descipline", "error");
      return;
    } else if (activity_number === "") {
      showToastMessage("Add Activity Number", "error");
      return;
    } else if (operation === "") {
      showToastMessage("Add Operation", "error");
      return;
    } else if (file_url === "") {
      showToastMessage("Add document to upload", "error");
      return;
    }
    // updateOnSubmitted(true);
    const data = new FormData();
    data.append("file_path", file_url);
    data.append("project_name", project_name);
    data.append("project_number", project_number);
    data.append("descipline", descipline);
    data.append("activity_number", activity_number);
    data.append("supplier_name", supplier_name);
    data.append("operation", operation);
    data.append("sub_part", sub_part);
    data.append("document_revised", document_revised);
    data.append("file_tracking_number", file_tracking_number);
    AddDocument(data).then((response) => {
      if (response.code === 200) {
        updateOnSubmitted(false);
        updateFormField({
          project_name: "",
          project_number: "",
          descipline: "",
          activity_number: "",
          operation: "",
          sub_part: "",
          supplier_name: "",
          document_revised: "",
          file_tracking_number: "",
        });
        showToastMessage("File Uploaded Successfully", "success");
      } else if (response.code !== 200) {
        console.log(response);
        updateOnSubmitted(false);
        showToastMessage("File Added Successfully", "error");
      }
    });
  };
  let row1 = (
    <Row>
      <Col className="text-center mb-3">
        <p className="font-weight-normal mb-2 mt-3">Uploading File</p>
        <p className="mb-2">
          <small>{filename}</small>{" "}
          <AiFillCloseCircle
            className="ml-2"
            size={20}
            color="black"
            onClick={() => onDelete()}
          />
        </p>

        <p className="mt-2 mb-2" onClick={() => history.push("/")}></p>

        <Progress max="100" value={progress} barClassName="progress-bar-info" />
        <div
          className="mb-3"
          style={{
            fontSize: 14,
          }}
        >
          {progress}%
        </div>
      </Col>
    </Row>
  );
  let row2 = (
    <Row>
      <Col className="text-center">
        <BsFillCheckCircleFill color="#188C70" size={50} />
        <p className="font-weight-normal mb-3 mt-3">
          File Uploaded SuccessFully
        </p>
        <p className="mb-2">
          <small>{filename}</small>
          <MdDelete
            onClick={() => onDelete()}
            className="ml-2"
            size={18}
            color="red"
          />
        </p>
        <p className="mt-2 mb-2" onClick={() => history.push("/")}>
          <ins>Back to home page</ins>
        </p>
      </Col>
    </Row>
  );
  let row3 = (
    <>
      {/* <Row className="text-center">
        <Col>
          <span className="drag-drop">Drag and Drop your files here</span>
          <DragDrop onChange={onFileChange} />{" "}
        </Col>{" "}
      </Row>
      <p className="text-center">OR</p> */}
      <Row className="text-center">
        <Col>
          <DragDrop onChange={onFileChange} />{" "}
          {/* <label htmlFor="btn" className="upload_btn">
            <MdOutlineFolderOpen size={20} className="mb-1 mr-1" /> Browse File{" "}
          </label>
          <input
            accept=".pdf"
            type="file"
            style={{ display: "none" }}
            id="btn"
            onChange={onFileChange}
          />{" "} */}
        </Col>
      </Row>
    </>
  );
  return (
    <div className="main ">
      <IndexNavbar defaultColor={true} />
      <div className="section ">
        <ToastContainer />
        <Container className="mt-3">
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <p style={{ marginTop: "2rem" }}>
                <span
                  className="home pointer-hand"
                  onClick={() => {
                    history.push("/index");
                  }}
                >
                  Home
                </span>
                <span className="search_result">/ Upload Document</span>
              </p>

              <h2 className="font-weight-light mt-2">Upload Document</h2>
              <Form className="contact-form">
                <div className="main_bloc ">
                  {pdfuploaded ? row1 : isUploaded ? row2 : row3}
                </div>
                <Row>
                  <Col md="6">
                    <label className="required form-label">Project Name</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <AiOutlineProject />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-label"
                        name="project_name"
                        value={formField.project_name}
                        placeholder="Project Name"
                        type="text"
                        maxLength={30}
                        onChange={onFormFieldChange}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    <label className="required form-label">
                      Project No (Supplier)
                    </label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <TbNumbers />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="project_number"
                        value={formField.project_number}
                        placeholder="Project Number"
                        type="text"
                        className="form-label"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        maxLength={30}
                        onChange={onFormFieldChange}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <label className="form-label">Supplier Name</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <GiPlayerPrevious />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="supplier_name"
                        className="form-label"
                        value={formField.supplier_name}
                        placeholder="Supplier Name"
                        type="text"
                        maxLength={30}
                        onChange={onFormFieldChange}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    <label className="required form-label">Discipline</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <IoBarcodeSharp />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="descipline"
                        className="form-label"
                        value={formField.descipline}
                        placeholder="Discipline"
                        type="text"
                        maxLength={30}
                        onChange={onFormFieldChange}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <label className="required form-label">
                      Activity Number
                    </label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <TbActivity />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-label"
                        name="activity_number"
                        value={formField.activity_number}
                        placeholder="Activity Number"
                        type="text"
                        maxLength={30}
                        onChange={onFormFieldChange}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    <label className="required form-label">Operation</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <VscSymbolProperty />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="operation"
                        className="form-label"
                        value={formField.operation}
                        placeholder="Operation"
                        type="text"
                        maxLength={30}
                        onChange={onFormFieldChange}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <label className="form-label">Sub-part</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <BsSubtract />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="sub_part"
                        value={formField.sub_part}
                        placeholder="Sub-part"
                        className="form-label"
                        type="text"
                        maxLength={30}
                        onChange={onFormFieldChange}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    <label className="form-label">Document Revised</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <HiDocumentText />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="document_revised"
                        value={formField.document_revised}
                        className="form-label"
                        placeholder="Document Revised"
                        type="text"
                        maxLength={30}
                        onChange={onFormFieldChange}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <label className="form-label">File Tracking Number</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <BsFileEarmarkCheck />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="file_tracking_number"
                        value={formField.file_tracking_number}
                        placeholder="File Tracking Number"
                        type="text"
                        maxLength={30}
                        className="form-label"
                        onChange={onFormFieldChange}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="ml-auto mr-auto" md="4">
                    <Button
                      style={{
                        background: "#36454F",
                        width: 250,
                        height: 50,
                        borderRadius: 35,
                      }}
                      onClick={onSubmitData}
                      disabled={onSubmitted}
                      className="btn-fill font-weight-bold mt-3 mb-5 pb-2 text-center"
                      color="#fffff"
                      size="lg"
                    >
                      SUBMIT
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
