import axios from "axios";
import LocalStorageService from "services/LocalStorageService";
import { LOGIN_ACCESS_TOKEN } from "../utils/apiUrls";

let authAxios = axios.create({});
// const LocalStorageService = LocalStorageService.getService();
// Add a request interceptor
authAxios.interceptors.request.use(
  (config) => {
    config.headers = {
      Authorization: `Bearer ${LocalStorageService.getAccessToken()}`,
      Accept: "application/json",
    };
    return config;
  },
  (error) => Promise.reject(error)
);

//Add a response interceptor

authAxios.interceptors.response.use(
  (response) => {
    console.log("respo");
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      originalRequest.url === LOGIN_ACCESS_TOKEN
    ) {
      localStorage.clear();
      window.location.reload();
    }
    if (
      error.response.status === 400 &&
      originalRequest.url === LOGIN_ACCESS_TOKEN
    ) {
      localStorage.clear();
      window.location.reload();
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = LocalStorageService.getRefreshToken();
      const res = await authAxios.post(LOGIN_ACCESS_TOKEN, {
        refresh: refreshToken,
      });
      console.log("res", res);
      if (res.status === 200) {
        LocalStorageService.setAccessToken(res.data.access);
        authAxios.defaults.headers.common["Authorization"] =
          "Bearer " + LocalStorageService.getAccessToken();
        return authAxios(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export default authAxios;
