import React from "react";
import { Container } from "reactstrap";

function Footer(props) {
  return (
    <footer  style={{position:props.position ? props.position : 'fixed'}} className="footer fixed-bottom">
          <div className="d-flex">
            
                {/* <p className="d-inline mt-2">Powered By</p>
                <a href="https://stech.ai/" target="_blank">
                  <img
                    alt="..."
                    height={25}
                    className="mb-0 ml-2 mt-1"
                    src={require("assets/img/stech.png")}
                  />
                </a> */}
              </div>
    </footer>
  );
}

export default Footer;
