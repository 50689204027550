import React from "react";
import { MdOutlineFolderOpen } from "react-icons/md";
const DragDrop = ({ onChange }) => {
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleChange = function (e) {
    e.preventDefault();
    onChange(e);
  };

  return (
    <div
      onDrop={(e) => handleChange(e)}
      onDragOver={handleDragOver}
      style={{ width: "100%", height: "100%" }}
    >
      <p className="paragraph_drag mb-0">Drag and Drop your files here</p>
      <p className="text-center para_OR">OR</p>
      <label htmlFor="btn" className="upload_btn mb-2">
        <MdOutlineFolderOpen size={20} className="mb-1 mr-1" /> Browse File{" "}
      </label>
      <input
        type="file"
        accept=".pdf"
        style={{ display: "none" }}
        id="btn"
        onChange={(e) => handleChange(e)}
      />
    </div>
  );
};
export default DragDrop;
